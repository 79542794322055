import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    text: String
  }

  copy(e) {
    e.preventDefault()

    navigator.clipboard.writeText(this.textValue)
    this.element.textContent = "copiado!"
  }
}
