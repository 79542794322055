import Rails from "@rails/ujs"
import { loadStripe } from '@stripe/stripe-js'
import { loadScript } from "@paypal/paypal-js"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "daysField", "limitField", "totalAmountLabel", "form", "cardErrors" ]
  static values = {
    stripeKey: String,
    clientEmail: String,
    paypalKey: String
  }

  initialize() {
    this.setTotalAmount()
    this.setupStripe()
    this.setupPaypal()
  }

  setTotalAmount(e) {
    this.totalAmount = this.daysFieldTarget.value * this.limitFieldTarget.value.split(',')[0]
    this.totalAmountLabelTarget.textContent = `$${this.totalAmount} MXN`
  }

  async setupStripe() {
    this.stripe = await loadStripe(this.stripeKeyValue)

    const elements = this.stripe.elements({
      locale: 'es'
    })

    var style = {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      }
    }

    this.cardElement = elements.create("card", { style: style })
    this.cardElement.mount("#card-element")

    this.cardElement.on('change', function(event) {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    }.bind(this))
  }

  setupPaypal() {
    loadScript({ 
      "client-id": this.paypalKeyValue, 
      "currency": "MXN",
      "locale": "es_MX"
    })
    .then((paypal) => {
      paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,

        style: {
          tagline: false
        },

        // Sets up the transaction when a payment button is clicked
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: this.totalAmount
              },
              description: `Publicarse en variegada.com por ${this.daysFieldTarget.value * 30} días`
            }],
            application_context: {
              brand_name: "Variegada",
              shipping_preference: "NO_SHIPPING"
            }
          })
        },

        // Finalize the transaction after payer approval
        onApprove: (data, actions) => {
          return actions.order.capture().then((orderData) => {
            const transaction = orderData.purchase_units[0].payments.captures[0]

            Rails.ajax({
              type: "POST",
              url: "/monthly_accesses/create_paypal",
              data: new URLSearchParams({
                days: this.daysFieldTarget.value * 30,
                limit: this.limitFieldTarget.value.split(',')[1],
                amount: this.totalAmount,
                identifier: transaction.id
              }).toString(),
              success: (response) => {
                return window.location = "/cuenta/plan?p=t"
              },
              error: (response) => {
                console.alert("Sucedió un error. Por favor intenta de nuevo o contáctanos para soporte.")
              }
            })
          })
        }
      }).render('#paypal-button-container')
    })
    .catch((err) => {
      console.error("failed to load the PayPal JS SDK script", err)
    })
  }

  toggleForm(e) {
    this.formTargets.forEach(el => el.style.display = 'none')
    document.getElementsByClassName(e.currentTarget.value)[0].style.display = 'block'
  }

  handleCardForm(e) {
    e.preventDefault()
    e.stopPropagation()

    Rails.disableElement(this.cardForm)

    let form_data = {
      amount: this.totalAmount,
      days: this.daysFieldTarget.value * 30,
      limit: this.limitFieldTarget.value.split(',')[1]
    }

    Rails.ajax({
      type: "POST",
      url: '/monthly_accesses/stripe_intent',
      data: new URLSearchParams(form_data).toString(),
      success: (response) => {
        this.handleStripePayment(response.stripe_secret)
      }
    })
  }

  handleStripePayment(stripe_secret) {
    this.stripe.confirmCardPayment(stripe_secret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          email: this.clientEmailValue
        }
      }
    }).then(function(result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.cardErrorsTarget.textContent = result.error.message
        Rails.enableElement(this.cardForm)
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          Rails.ajax({
            type: "POST",
            url: this.cardForm.getAttribute("action"),
            data: new URLSearchParams({ stripe_payment_id: result.paymentIntent.id }).toString(),
            success: (response) => {
              return window.location = "/cuenta/plan?p=t"
            },
            error: (response) => {
              this.cardErrorsTarget.textContent = "El pago no pudo ser realizado, por favor intenta de nuevo."
              Rails.enableElement(this.cardForm)
            }
          })
        }
      }
    }.bind(this))
  }

  get cardForm() {
    return document.getElementsByClassName("form-cc")[0]
  }
}
