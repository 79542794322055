import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"
import intlTelInputUtils from 'intl-tel-input/build/js/utils.js'

export default class extends Controller {
  static targets = [ "phoneField", "invalidFeedback", "phoneHiddenField" ]

  initialize() {
    this.iti = intlTelInput(this.phoneFieldTarget, {
      initialCountry: "mx",
      preferredCountries: ["mx", "us"],
      utilsScript: intlTelInputUtils
    })
  }

  handleSubmit(e) {
    if (!this.validatePhoneNumber()) {
      e.preventDefault()
      e.stopPropagation()
      this.phoneFieldTarget.focus()
      return false
    }

    this.phoneHiddenFieldTarget.value = this.iti.getNumber()
  }

  validatePhoneNumber(e) {
    const valid = this.iti.isValidNumber()

    if (!valid) {
      this.phoneFieldTarget.classList.add('is-invalid')
      this.invalidFeedbackTarget.style.display = 'block'
      return false
    } else {
      this.phoneFieldTarget.classList.remove('is-invalid')
      this.invalidFeedbackTarget.style.display = 'none'
      return true
    }
  }
}
