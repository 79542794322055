import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  initialize() {
    this.originalTop = 220
    this.onScrollRunning = false
  }

  onScroll(e) {
    if (!window.isMobile) {
      return
    }

    if (!this.onScrollRunning) {
      this.onScrollRunning = true
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.doSomething.bind(this))
      } else {
        setTimeout(this.doSomething.bind(this), 66)
      }
    }
  }

  doSomething() {
    if (window.scrollY >= this.originalTop) {
      this.element.classList.add("fixed")
    } else {
      this.element.classList.remove("fixed")
    }

    this.onScrollRunning = false
  }

  closeFilterCollapse(e) {
    const collapse = Collapse.getInstance(e.params.target)
    collapse.hide()
  }
}
