import { Fancybox } from "@fancyapps/ui"

document.addEventListener("turbo:load", (e) => {
  trix.init()
  fancybox.init()

  window.isMobile = window.matchMedia("only screen and (max-width: 991px)").matches
})

const fancybox = {
  init: () => {
    Fancybox.destroy()
    Fancybox.bind("[data-fancybox]")
    Fancybox.bind('[data-fancybox="gallery"]', {
      animated: false,
      showClass: false,
      hideClass: false,
      dragToClose: false,

      Image: {
        zoom: false,
      },

      Toolbar: {
        display: [{ id: "counter", position: "center" }, "close"],
      }
    })
  }
}

const trix = {
  init: () => {
    window.addEventListener("trix-file-accept", (event) => {
      event.preventDefault()
      alert("Agregar archivos no está habilitado.")
    })
  }
}
