import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleSubmit(e) {
    if (!this.validateForm()) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }

  validateForm() {
    this.element.classList.add('was-validated')

    if (!this.element.checkValidity()) {
      this.element.querySelectorAll(':invalid')[0].focus()
      return false
    }

    return true
  }
}
