import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "label" ]

  copy(e) {
    e.preventDefault()

    navigator.clipboard.writeText(e.params.url)
    this.labelTarget.textContent = "Copiado!"
    this.labelTarget.classList.add("fst-italic")
  }

  reset() {
    this.labelTarget.textContent = "Copiar Liga"
    this.labelTarget.classList.remove("fst-italic")
  }

  shareSocial(e) {
    e.preventDefault()

    if (window.navigator.share) {
      navigator.share({
        title: document.title,
        text: e.params.title,
        url: e.params.url
      })
    } else {
      window.open(e.params.altUrl, '_blank')
    }
  }
}
