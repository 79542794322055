import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["form", "emailField", "alertsTypeField"]
  static values = {
    close: Boolean
  }

  initialize() {
    if (this.closeValue && !localStorage.getItem("_variegada-hide-form")) {
      this.element.classList.remove("d-none")
      this.element.classList.add("d-flex")
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    let email = this.emailFieldTarget.value
    let alerts = this.alertsTypeFieldTarget.value
    let format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (!email.match(format)) {
      this.emailFieldTarget.classList.add('is-invalid')
      this.emailFieldTarget.focus()
      return false
    } else {
      this.emailFieldTarget.classList.remove('is-invalid')
    }

    Rails.disableElement(this.formTarget)

    Rails.ajax({
      type: "POST",
      url: this.formTarget.getAttribute("action"),
      data: new URLSearchParams({ email: email, alerts: alerts }).toString(),
      success: (response) => {
        Rails.enableElement(this.formTarget)

        this.emailFieldTarget.value = ""

        if (this.closeValue) {
          this.element.classList.add('d-none')
          localStorage.setItem("_variegada-hide-form", true)
        }

        if (response.new) {
          alert(`Te hemos enviado un correo a ${email} para confirmar tu suscripción! También revisa en tu carpeta de spam.`)
        } else {
          alert("¡Tu suscripción está lista! Pronto empezarás a recibir tus notificaciones directo en tu correo.")
        }
      }
    })
  }

  closeForm(e) {
    e.preventDefault()

    this.element.classList.add('d-none')
    localStorage.setItem("_variegada-hide-form", true)
  }
}
