import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  connect() {
    if (window.matchMedia("only screen and (max-width: 991px)").matches) {
      return
    }

    super.connect()
  }
}
