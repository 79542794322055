import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  registerClick(e) {
    Rails.ajax({
      type: "POST",
      url: '/analytics',
      data: new URLSearchParams({ 
        user_id: e.params.userId, 
        product_id: e.params.productId, 
        social: e.params.social 
      }).toString(),
      success: (response) => {
        
      }
    })
  }
}
