import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "categoryIdProds", "stateProds", "stateSellers" ]

  handleSelectProducts(e) {
    if (this.categoryIdProdsTarget.value && this.stateProdsTarget.value) {
      this.element.requestSubmit()
    }
  }

  handleSelectSellers(e) {
    if (this.stateSellersTarget.value) {
      this.element.requestSubmit()
    }
  }
}
