import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Rails from "@rails/ujs"
import Trix from "trix"

export default class extends Controller {
  static values = {
    totalFiles: Number
  }
  static targets = [ "progressContainer", "filesContainer" ]

  initialize() {
    this.filesBeingUploaded = 0
    this.directUploadCallbacks = 0

    // this.handleVideoEmbeds()
  }

  handleVideoEmbeds() {
    window.addEventListener("trix-paste", (event) => {
      let html = event.paste.string
      let range = event.paste.range
      let editor = document.querySelector("trix-editor").editor
      
      editor.setSelectedRange(range)
      setTimeout(() => {
        editor.deleteInDirection("backward")
        let attachment = new Trix.Attachment({ content: html })
        editor.insertAttachment(attachment)
      }, 50)
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this.validateForm()) {
      return false
    }

    this.element.requestSubmit()
  }

  validateForm() {
    this.element.classList.add('was-validated')

    if (!this.element.checkValidity()) {
      this.element.querySelectorAll(':invalid')[0].focus()
      return false
    }

    if (this.element.querySelectorAll('.is-invalid').length) {
      this.element.querySelectorAll('.is-invalid')[0].focus()
      return false
    }

    if (this.totalFilesValue == 0 || document.querySelectorAll("img[style='object-fit: contain;']").length < 1) {
      alert("Por favor sube al menos 1 imagen.")
      return false
    }

    return true
  }

  handleFiles(e) {
    this.input = e.currentTarget
    this.files = Array.from(this.input.files).slice(0, (5 - this.totalFilesValue))

    let valid = true
    Array.from(this.files).forEach((file) => {
      let megas = file.size/1024/1024

      if (megas > 11) {
        alert("Por favor revisa que cada archivo sea menor de 11 MB")
        valid = false
      }
    })

    if (!valid) {
      return false
    }

    // start uploading process
    Array.from(this.files).forEach(file => this.uploadFile(file))
    this.input.value = null
    this.totalFilesValue += this.files.length
    this.filesBeingUploaded += this.files.length
  }

  uploadFile(file) {
    Rails.disableElement(this.element)

    const url = this.input.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        // handle video
        if (file.type.includes("video")) {
          const url = `/rails/active_storage/blobs/${blob.signed_id}/product_video`

          const fileHtml = `<video src="${url}" controls="controls" class="handle img-fluid w-250 h-250 border border-gray-300 mb-2"></video>`

          this.filesContainerTarget.insertAdjacentHTML("beforeend", `
            <div id="${blob.signed_id}" class="d-flex flex-column align-items-center cursor-move me-3 mb-3">
              ${fileHtml}
              <a data-action="product-form#removeFile" data-product-form-id-param="${blob.signed_id}" class="btn btn-link p-0" href="#!">Borrar video</a>
              <input type="hidden" name="product[videos][]" value="${blob.signed_id}">
            </div>
          `)

          this.progressContainerTarget.removeChild(this.progressContainerTarget.querySelector(".finished"))
          this.filesBeingUploaded -= 1
          if (this.filesBeingUploaded == 0) {
            Rails.enableElement(this.element)
          }

        } else {
          // handle image
          Rails.ajax({
            type: "POST",
            url: '/uploads/blob_variant_url',
            data: new URLSearchParams({ blob_id: blob.id }).toString(),
            success: (response) => {
              const fileHtml = `<img src="${response.url}" class="handle img-fluid w-250 h-250 border border-gray-300 mb-2" style="object-fit: contain;">`

              this.filesContainerTarget.insertAdjacentHTML("beforeend", `
                <div id="${blob.signed_id}" class="d-flex flex-column align-items-center cursor-move me-3 mb-3">
                  ${fileHtml}
                  <a data-action="product-form#removeFile" data-product-form-id-param="${blob.signed_id}" class="btn btn-link p-0" href="#!">Borrar imagen</a>
                  <input type="hidden" name="product[images][]" value="${blob.signed_id}">
                </div>
              `)

              this.progressContainerTarget.removeChild(this.progressContainerTarget.querySelector(".finished"))
              this.filesBeingUploaded -= 1
              if (this.filesBeingUploaded == 0) {
                Rails.enableElement(this.element)
              }
            }
          })
        }
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    this.directUploadCallbacks += 1
    const id = `progress-${this.directUploadCallbacks}`
    this.progressContainerTarget.insertAdjacentHTML("beforeend", `
      <span id="${id}" class="d-inline-block fw-bold me-2 mb-3"></div>
    `)
    const el = document.getElementById(id)
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event, el))
  }

  directUploadDidProgress(event, el) {
    const calc = Math.ceil((event.loaded * 100) / event.total)
    if (calc == 100) {
      el.textContent = "Procesando..."
      el.classList.add("finished")
    } else {
      el.textContent = `${calc}%`
    }
  }

  removeFile(e) {
    e.preventDefault()

    // remove myself
    const el = document.getElementById(e.params.id)
    el.parentNode.removeChild(el)

    this.totalFilesValue -= 1
  }
}
