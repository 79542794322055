import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "scrollWrapper", "productsWrapper", "product", "backBtn", "forwardBtn" ]
  static values = {
    recentProducts: Number
  }

  initialize() {
    let computed = getComputedStyle(this.productTarget)
    let size = (parseInt(computed.width) + parseInt(computed.marginRight)) * this.recentProductsValue

    this.productsWrapperTarget.style.width = `${size}px`

    this.onScrollRunning = false
  }

  onScroll(e) {
    if (window.isMobile) {
      return
    }

    if (!this.onScrollRunning) {
      this.onScrollRunning = true
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.handleScroll.bind(this))
      } else {
        setTimeout(this.handleScroll.bind(this), 66)
      }
    }
  }

  handleScroll() {
    this.calculateScroll()

    this.onScrollRunning = false
  }

  calculateScroll() {
    let width = this.scrollWrapperTarget.scrollWidth
    let scrollLeft = this.scrollWrapperTarget.scrollLeft

    if ((width - scrollLeft) < 1000) {
      this.forwardBtnTarget.style.display = 'none'
    } else {
      this.forwardBtnTarget.style.display = 'block'
    }

    if (scrollLeft > 0) {
      this.backBtnTarget.style.display = 'block'
    } else {
      this.backBtnTarget.style.display = 'none'
    }
  }

  handleBack(e) {
    let scrollLeft = screen.width < 1200 ? 500 : 700

    this.scrollWrapperTarget.scrollTo({
      left: this.scrollWrapperTarget.scrollLeft - scrollLeft,
      behavior: "smooth"
    })
  }

  handleForward(e) {
    let scrollLeft = screen.width < 1200 ? 500 : 700

    this.scrollWrapperTarget.scrollTo({
      left: this.scrollWrapperTarget.scrollLeft + scrollLeft,
      behavior: "smooth"
    })
  }

  mouseEnter(e) {
    if (window.isMobile) {
      return
    }

    if (this.recentProductsValue > 5) {
      this.calculateScroll()
    }
  }

  mouseLeave(e) {
    if (window.isMobile) {
      return
    }

    this.backBtnTarget.style.display = 'none'
    this.forwardBtnTarget.style.display = 'none'
  }
}
