import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["form", "messageChars", "successMessage"]

  updateMessageChars(e) {
    this.messageCharsTarget.textContent = e.currentTarget.value.length
  }

  reset(e) {
    this.formTarget.style.display = 'block'
    this.successMessageTarget.style.display = 'none'
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this.validateForm()) {
      return false
    }

    Rails.disableElement(this.formTarget)

    Rails.ajax({
      type: "POST",
      url: this.formTarget.getAttribute("action"),
      data: new URLSearchParams(new FormData(this.formTarget)).toString(),
      success: (response) => {
        Rails.enableElement(this.formTarget)
        this.formTarget.reset()
        this.formTarget.classList.remove('was-validated')

        this.formTarget.style.display = 'none'
        this.successMessageTarget.style.display = 'block'
      },
      error: (response) => {
        alert("El mensaje no pudo ser enviado. Por favor vuelve a intentarlo.")
      }
    })
  }

  validateForm() {
    this.formTarget.classList.add('was-validated')

    if (!this.formTarget.checkValidity()) {
      this.formTarget.querySelectorAll(':invalid')[0].focus()
      return false
    }

    return true
  }
}
