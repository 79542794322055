import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "emailForm", "emailFeedback" ]

  handleEmailSubmit(e) {
    if (!this.validateEmailForm()) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }

  validateEmailForm() {
    this.emailFormTarget.classList.add('was-validated')

    if (!this.emailFormTarget.checkValidity()) {
      this.emailFormTarget.querySelectorAll('.form-control:invalid')[0].focus()
      return false
    }

    if (this.emailFormTarget.querySelectorAll('.is-invalid').length) {
      this.emailFormTarget.querySelectorAll('.is-invalid')[0].focus()
      return false
    }

    return true
  }

  validateEmail(e) {
    const value = e.currentTarget.value.trim()

    Rails.ajax({
      type: "POST",
      url: '/users/validate_email',
      data: new URLSearchParams({ email: value }).toString(),
      success: (response) => {
        if (!response.valid) {
          e.target.classList.add('is-invalid')
          this.emailFeedbackTarget.textContent = 'Este correo ya está registrado.'
        } else {
          e.target.classList.remove('is-invalid')
          this.emailFeedbackTarget.textContent = 'Por favor ingresa un correo válido.'
        }
      }
    })
  }
}
