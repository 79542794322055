import Rails from "@rails/ujs"
import { Collapse, Modal, Dropdown } from "bootstrap"
import '@stripe/stripe-js'
import "@fancyapps/ui/dist/fancybox.css"
import 'intl-tel-input/build/css/intlTelInput.css'
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"
import "channels"
import "controllers"
import "site"

Rails.start()
ActiveStorage.start()
