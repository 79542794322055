import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "link" ]

  handleFile(e) {
    this.input = e.currentTarget
    const megas = this.input.files[0].size/1024/1024

    if (megas > 11) {
      alert("Imagen debe ser menor a 11 MB")
      return false
    }

    this.uploadFile(this.input.files[0])
    this.input.value = null
  }

  uploadFile(file) {
    const url = this.input.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.input.name

        this.element.appendChild(hiddenField)
        this.element.requestSubmit()
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const calc = Math.ceil((event.loaded * 100) / event.total)
    this.linkTarget.textContent = `${calc}%`
  }
}
