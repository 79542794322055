import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "username", "usernameFeedback", "usernameExampleLabel", "shippingDetailsChars" ]

  // username

  updateUsernameText(e) {
    this.usernameExampleLabelTarget.textContent = 'Tu liga:'
    this.usernameTarget.textContent = e.currentTarget.value.toLowerCase()
  }

  validateUsername(e) {
    const value = e.currentTarget.value.trim()

    if (!value) {
      return false
    }

    const regex = /^[a-zA-Z0-9_.]+$/.exec(value)
    const valid = !!regex

    if (!valid) {
      e.currentTarget.classList.add('is-invalid')
      this.usernameFeedbackTarget.textContent = 'Solo puede contener a-z, 0-9, puntos y guiones bajos.'
      return false
    }

    const regex_min_1_char = /[a-zA-Z]+/i.exec(value)
    const valid_min_1_char = !!regex_min_1_char

    if (!valid_min_1_char) {
      e.currentTarget.classList.add('is-invalid')
      this.usernameFeedbackTarget.textContent = 'Debe contener mínimo 1 caracter.'
      return false
    }

    const regex_start_end_consecutive_dots = /^(?!\.)(?!.*\.$)(?!.*\._)(?!.*\.\.)/.exec(value)
    const valid_start_end_consecutive_dots = !!regex_start_end_consecutive_dots

    if (!valid_start_end_consecutive_dots) {
      e.currentTarget.classList.add('is-invalid')
      this.usernameFeedbackTarget.textContent = 'Username es inválido.'
      return false
    }

    if (value.length < 3) {
      e.currentTarget.classList.add('is-invalid')
      this.usernameFeedbackTarget.textContent = 'Debe ser de mínimo 3 caracteres.'
      return false 
    }

    Rails.ajax({
      type: "POST",
      url: '/users/validate_username',
      data: new URLSearchParams({ username: value }).toString(),
      success: (response) => {
        if (!response.valid) {
          e.target.classList.add('is-invalid')
          if (response.reason == "existing") {
            this.usernameFeedbackTarget.textContent = 'Este username ya está siendo usado.'
          } else if (response.reason == "blacklist") {
            this.usernameFeedbackTarget.textContent = 'Lo sentimos, este username no está permitido. Por favor ingresa uno distinto.'
          }
        } else {
          e.target.classList.remove('is-invalid')
        }
      }
    })
  }


  // shipping details chars

  updateShippingDetailsChars(e) {
    this.shippingDetailsCharsTarget.textContent = e.currentTarget.value.length
  }


  // checkbox changed

  checkboxChanged(e) {
    Rails.ajax({
      type: "POST",
      url: "/cuenta/update_ajax",
      data: new URLSearchParams(new FormData(this.element)).toString(),
      success: (response) => {
      }
    })
  }

  // submit

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this.validateForm()) {
      return false
    }

    Rails.disableElement(this.element)

    this.element.requestSubmit()
  }

  validateForm() {
    this.element.classList.add('was-validated')

    if (!this.element.checkValidity()) {
      this.element.querySelectorAll('.form-control:invalid')[0].focus()
      return false
    }

    if (this.element.querySelectorAll('.is-invalid').length) {
      this.element.querySelectorAll('.is-invalid')[0].focus()
      return false
    }

    return true
  }
}
