import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static values = {
    disableMobile: Boolean
  }

  initialize() {
    if (this.hasDisableMobileValue) {
      if (!window.isMobile) {
        this.tooltip = new Tooltip(this.element)
      }
    } else {
      this.tooltip = new Tooltip(this.element)
    }
  }

  hide(e) {
    this.tooltip.hide()
  }
}
