import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  static targets = [ "plantsMenu", "plantsToggle", "accountMenu", "accountToggle" ]

  closeNavbarCollapse(e) {
    if (window.isMobile) {
      const collapse = Collapse.getInstance("#navbar-collapse")
      if (collapse) {
        collapse.hide()
      }
    }
  }

  showPlantsDropdown(e) {
    if (window.isMobile) {
      return
    }

    this.plantsToggleTarget.setAttribute('aria-expanded', true)
    this.plantsToggleTarget.classList.add('nav-link-bg')
    this.plantsMenuTarget.classList.add('show')
  }

  hidePlantsDropdown(e) {
    if (window.isMobile) {
      return
    }

    this.plantsToggleTarget.setAttribute('aria-expanded', false)
    this.plantsToggleTarget.classList.remove('nav-link-bg')
    this.plantsMenuTarget.classList.remove('show')
  }

  showAccountDropdown(e) {
    if (window.isMobile) {
      return
    }

    this.accountToggleTarget.setAttribute('aria-expanded', true)
    this.accountToggleTarget.classList.add('nav-link-bg')
    this.accountMenuTarget.classList.add('show')
  }

  hideAccountDropdown(e) {
    if (window.isMobile) {
      return
    }

    this.accountToggleTarget.setAttribute('aria-expanded', false)
    this.accountToggleTarget.classList.remove('nav-link-bg')
    this.accountMenuTarget.classList.remove('show')
  }

  showArrow(e) {
    if (window.isMobile) {
      return
    }

    const arrow = e.currentTarget.getElementsByClassName("navbar-arrow")[0]
    arrow.classList.add("visible")
  }

  hideArrow(e) {
    if (window.isMobile) {
      return
    }

    const arrow = e.currentTarget.getElementsByClassName("navbar-arrow")[0]
    arrow.classList.remove("visible")
  }
}
