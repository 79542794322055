import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { Popover } from "bootstrap"

export default class extends Controller {
  static values = {
    url: String
  }

  initialize() {
    this.html = ""
    this.popover = Popover.getOrCreateInstance(this.element, {
      fallbackPlacements: ['top', 'bottom'],
      sanitize: false
    })
  }

  showUserCard(e) {
    this.timeout = setTimeout(this.fetchCard.bind(this), 600)
  }

  hideUserCard(e) {
    clearTimeout(this.timeout)
    this.timeout = null
    this.popover.hide()
    this.element.querySelectorAll(".popover").forEach(el => el.remove())
  }

  fetchCard() {
    if (this.html) {
      this.showCard()
    } else {
      Rails.ajax({
        type: "GET",
        url: this.urlValue,
        success: (response) => {
          this.html = response.body.innerHTML
          this.showCard()
        }
      })
    }
  }

  showCard() {
    this.popover._config.content = this.html
    this.popover.show()
  }
}
