import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "categorySelect", "filterButton", "productsContainer" ]

  filterProducts(e) {
    let categoryId = this.categorySelectTarget.value

    if (categoryId) {
      this.element.querySelectorAll(`[data-profile-products-category-id]`).forEach(e => e.classList.add("d-none"))
      this.element.querySelectorAll(`[data-profile-products-category-id="${categoryId}"]`).forEach(e => e.classList.remove("d-none"))
    } else {
      this.element.querySelectorAll(`[data-profile-products-category-id]`).forEach(e => e.classList.remove("d-none"))
    }
  }

  sortByViews(e) {
    e.preventDefault()

    this.filterButtonTargets.forEach((el) => {
      el.classList.remove("active")
    })

    e.currentTarget.classList.add("active")

    // Get the parent <div>
    const parentDiv = this.productsContainerTarget

    // Get all child elements
    const children = Array.from(parentDiv.children)

    // Sort the children by data-profile-products-visits in descending order
    children.sort((a, b) => {
        const positionA = parseInt(a.getAttribute('data-profile-products-visits'), 10)
        const positionB = parseInt(b.getAttribute('data-profile-products-visits'), 10)
        return positionB - positionA
    })

    parentDiv.innerHTML = ""

    // Append the sorted children back to the parent <div>
    children.forEach(child => parentDiv.appendChild(child))
  }

  sortByPosition(e) {
    e.preventDefault()

    this.filterButtonTargets.forEach((el) => {
      el.classList.remove("active")
    })

    e.currentTarget.classList.add("active")

    // Get the parent <div>
    const parentDiv = this.productsContainerTarget

    // Get all child elements
    const children = Array.from(parentDiv.children)

    // Sort the children by data-profile-products-position in ascending order
    children.sort((a, b) => {
        const positionA = parseInt(a.getAttribute('data-profile-products-position'), 10)
        const positionB = parseInt(b.getAttribute('data-profile-products-position'), 10)
        return positionA - positionB
    })

    parentDiv.innerHTML = ""

    // Append the sorted children back to the parent <div>
    children.forEach(child => parentDiv.appendChild(child))
  }
}
