import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    setTimeout(() => {
      this.originalTop = document.querySelectorAll("[data-contact-seller-bar-product-seller] [data-contact-seller-bar-main-button]")[0].offsetTop + 200
    }, 1000)
    this.onScrollRunning = false
  }

  onScroll(e) {
    if (!window.isMobile) {
      return
    }

    if (!this.onScrollRunning) {
      this.onScrollRunning = true
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.doSomething.bind(this))
      } else {
        setTimeout(this.doSomething.bind(this), 66)
      }
    }
  }

  doSomething() {
    if (window.scrollY >= this.originalTop) {
      this.element.classList.add("fixed")
    } else {
      this.element.classList.remove("fixed")
    }

    this.onScrollRunning = false
  }
}
